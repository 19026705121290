import { isSameDay, parse } from "date-fns";
import momentTZ from "moment-timezone";
import { getTimezoneOffset } from "date-fns-tz";
import { convertDbDateToUtc } from "../../../utils/dateUtils";
import { PlanFragment } from "../../../graphql/graphql-generated.types";

type CalendarItem = Calendar["data"][0];

export const filterValidClasses = (
  plan: PlanFragment,
  calendarData: CalendarData[],
  timezone: string,
): CalendarData[] => {
  const filteredData = calendarData.filter((item) => {
    // since timestamps come from the server as if they were in UTC, we need to compare them with a current timestamp current the unix offset added back in
    const nowWithOffset = +new Date() / 1000 + getUnixOffsetSeconds(timezone);
    if (isClassNotInRestrictionBookingTo(plan, item)) {
      return false;
    }
    if (!isInTheRegistrationWindow(item, timezone)) {
      return false;
    }
    if (isClassWithoutAvailableSpots(item)) {
      return false;
    }
    if (isClassNotInCalendarTypes(plan, item)) {
      return false;
    }
    if (isOutsideRegistrationWindow(item, nowWithOffset)) {
      return false;
    }
    return true;
  });

  return filteredData;
};

export const filterCalendarDataByTimestamp = (
  calendarData: CalendarData[],
  timestamp: number,
): CalendarData[] => {
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const referenceDate = momentTZ(timestamp).utc().tz(localTz, true).toDate();
  return calendarData.filter((data) => {
    const startDatetime = parse(
      data.start_datetime,
      "yyyy-MM-dd HH:mm:ss",
      new Date(),
    );
    return isSameDay(startDatetime, referenceDate);
  });
};

const isClassNotInRestrictionBookingTo = (
  plan: PlanFragment,
  item: CalendarItem,
) => {
  return plan.redirectBookingTo && plan.redirectBookingTo !== item.type.uuid;
};

export const isInTheRegistrationWindow = (
  item: CalendarItem,
  timezone: string,
) => {
  const itemStart = convertDbDateToUtc(item.start_datetime, timezone);
  let result = true;

  // validate registration start. Zero means start immediately and don't require validation
  const regStartOffset = Number(item.registration_start_offset);
  if (regStartOffset !== 0) {
    const date = new Date();
    const itemStartWithOffset = new Date(itemStart);
    itemStartWithOffset.setMinutes(itemStart.getMinutes() + regStartOffset);
    if (date < itemStartWithOffset) {
      result = false;
    }
  }

  // validate registration end
  const regEndOffset = Number(item.registration_end_offset);
  const date = new Date();
  const itemStartWithOffset = new Date(itemStart);
  itemStartWithOffset.setMinutes(itemStart.getMinutes() + regEndOffset);
  if (date > itemStartWithOffset) {
    result = false;
  }

  return result;
};

const getUnixOffsetSeconds = (timezone?: string) => {
  // https://github.com/marnusw/date-fns-tz#gettimezoneoffset returns milliseconds offset
  return timezone ? getTimezoneOffset(timezone) / 1000 : 0;
};

const isClassWithoutAvailableSpots = (item: CalendarItem) => {
  return item.spots_available === 0;
};

const isRegistrationWindowNotStarted = (
  item: CalendarItem,
  timestamp: number,
) => {
  return item.registration_start !== 0 && item.registration_start > timestamp;
};

const isRegistrationWindowClosed = (item: CalendarItem, timestamp: number) => {
  return item.registration_cutoff !== 0 && item.registration_cutoff < timestamp;
};

/**
 * @param item -  CalendarItem
 * @param timestamp - unix timestamp in seconds
 * @returns boolean - true if the registration window is closed
 */
export const isOutsideRegistrationWindow = (
  item: CalendarItem,
  timestamp: number,
) => {
  return (
    isRegistrationWindowNotStarted(item, timestamp) ||
    isRegistrationWindowClosed(item, timestamp)
  );
};

const isClassNotInCalendarTypes = (plan: PlanFragment, item: CalendarItem) => {
  return (
    plan?.hasCalendarItemTypeRestriction &&
    !plan.calendarItemTypes?.includes(item.type.uuid)
  );
};

// TODO: add the correct logic
export const getExpiredDate = (
  plan: Pick<PlanFragment, "createTimestamp" | "expiration" | "expirationType">,
): Date | null => {
  const { createTimestamp, expiration, expirationType } = plan;
  if (!createTimestamp || !expiration || !expirationType) {
    return null;
  }
  return null;
};

export const isPlanExpired = (
  plan: Pick<PlanFragment, "createTimestamp" | "expiration" | "expirationType">,
  date: number,
): boolean => {
  const expirationDate = getExpiredDate(plan);
  if (!expirationDate) {
    return false;
  }
  return new Date(date) > expirationDate;
};

export const getCoachNames = (
  coach: [
    {
      uuid: string;
      first_name: string;
      last_name: string;
      checkin: false;
      primary_image: null;
    },
  ],
) => {
  if (!coach) {
    return "";
  }
  return coach
    .map((c) => {
      const parts = [c.first_name, c.last_name];
      return parts.filter(Boolean).join(" ");
    })
    .join(", ");
};
