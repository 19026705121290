import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { format } from "date-fns";
import { useRouter } from "next/router";
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { ToastColor } from "@pushpress/shared-components";
import { ApiError } from "../api";
import { useAppContext } from "../contexts/AppContext";
import { useErrorTracking } from "../components/ErrorTrackingProvider";
import { useToast } from "../components/ToastProvider";
import { getQueryStringObject } from "./browserUtils";
import { extractError } from ".";
import { getFormatBasedOnLocale } from "./locale";

type UseToggle = [
  boolean,
  { toggle: VoidFunction; on: VoidFunction; off: VoidFunction },
];

interface Discount {
  discountType: "FLAT" | "PERCENTAGE";
  discountValue: number;
}

export const useToggle = (initValue = false): UseToggle => {
  const [state, setState] = useState<boolean>(initValue);
  const toggle = useCallback(() => setState((s) => !s), []);
  const on = useCallback(() => setState(true), []);
  const off = useCallback(() => setState(false), []);
  const actions = useMemo(() => {
    return {
      toggle,
      on,
      off,
    };
  }, [off, on, toggle]);
  return [state, actions];
};

type UseFormInput<T> = [T, (key: string | number, value: unknown) => void];
export const useFormInput = <T>(
  defaultValues: T,
  immutableKeys: (string | number)[] = [],
): UseFormInput<T> => {
  const [state, setState] = useState(defaultValues);
  const setProperty = useCallback(
    (key: string | number, value: unknown) => {
      if (immutableKeys.includes(key)) {
        return;
      }
      setState((s) => ({ ...s, [key]: value }));
    },
    [immutableKeys],
  );
  return [state, setProperty];
};

export const useFormatters = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    client: { currencyIso, country },
  } = useAppContext();

  const formatCurrency = useCallback(
    (nu: number) => {
      return new Intl.NumberFormat(language, {
        style: "currency",
        currency: currencyIso,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(nu);
    },
    [language, currencyIso],
  );

  const formatPercent = useCallback(
    (nu: number) => {
      const nuFormatted = new Intl.NumberFormat(language, {
        maximumFractionDigits: 2,
      }).format(nu);
      return `${nuFormatted}%`;
    },
    [language],
  );

  const formatLongDate = useCallback(
    (timestamp?: number | string, utc?: boolean) => {
      const dateMoment = moment(timestamp);
      dateMoment.locale(language);
      return utc
        ? dateMoment.utc().format("dddd, D MMMM, YYYY")
        : dateMoment.format("dddd, D MMMM, YYYY");
    },
    [language],
  );

  const formatDate = useCallback(
    (date: string) => {
      return format(new Date(date), getFormatBasedOnLocale(country));
    },
    [country],
  );

  const formatTime = useCallback((timestamp: number | string) => {
    const dateMoment = moment(timestamp).utcOffset(timestamp);
    return dateMoment.format("h:mm a");
  }, []);

  const formatDiscount = useCallback(
    (discount: Discount) => {
      if (!discount) {
        return "";
      }
      if (discount.discountType === "PERCENTAGE") {
        return `${formatPercent(discount.discountValue)}`;
      }
      if (discount.discountType === "FLAT") {
        return `${formatCurrency(discount.discountValue)}`;
      }
      throw new Error("Invalid discount");
    },
    [formatCurrency, formatPercent],
  );

  const formatNumber = useCallback(
    (nu: number) => {
      const nuFormatted = new Intl.NumberFormat(language, {
        maximumFractionDigits: 2,
      }).format(nu);
      return nuFormatted;
    },
    [language],
  );

  return {
    formatCurrency,
    formatPercent,
    formatLongDate,
    formatDate,
    formatTime,
    formatDiscount,
    formatNumber,
  };
};

interface FromTo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: any;
}

type Changes = Record<string, FromTo>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericProps = Record<string, any>;

// TypeScript adaptation of https://usehooks.com/useWhyDidYouUpdate/
export const useWhyDidYouUpdate = (name: string, props: GenericProps): void => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<GenericProps>(props);

  useEffect(() => {
    if (previousProps && previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changes: Changes = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changes[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      if (Object.keys(changes).length) {
        console.log("[why-did-you-update]", name, changes);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

export const useURLSearchParams = () => {
  const router = useRouter();
  return getQueryStringObject(router.asPath);
};

export const useMounted = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted.current;
};

export interface UseAppMutationOptions<TData, TError, TVariables, TContext>
  extends UseMutationOptions<TData, TError, TVariables, TContext> {
  showMessageOnError?: boolean;
}

export function useAppMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseAppMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  >,
) {
  const { setToast } = useToast();

  const { notifyApiError } = useErrorTracking();

  const showMessageOnError = options?.showMessageOnError;

  const customOptions = {
    ...options,
    onError(
      error: TError,
      variables: TVariables,
      context: TContext | undefined,
    ) {
      if (showMessageOnError !== false) {
        setToast({
          status: ToastColor.Error,
          message: extractError(error),
        });
        if (error instanceof ApiError) {
          notifyApiError(error);
        }
      }
      options?.onError?.(error, variables, context);
    },
  };

  return useMutation<TData, TError, TVariables, TContext>(
    mutationFn,
    customOptions,
  );
}

export interface UseAppQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  showMessageOnError?: boolean;
}

export function useAppQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseAppQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) {
  const { setToast } = useToast();

  const { notifyApiError } = useErrorTracking();

  const showMessageOnError = options?.showMessageOnError;

  const customOptions = {
    ...options,
    onError(error: TError) {
      if (showMessageOnError !== false) {
        setToast({
          status: ToastColor.Error,
          message: extractError(error),
        });
        if (error instanceof ApiError) {
          notifyApiError(error);
        }
      }
      options?.onError?.(error);
    },
  };

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    customOptions,
  );
}
